<template>
   <section class="banner-holder">   
        <v-layout justify-space-around justify-center>
            <v-flex xs9 sm9 offset-sm1 class="">
                    <v-card flat tile color="" width="100%" class="banner transparent" height="12vh">
                        <v-card-text class="mt-6">
                            <v-row>
                                 <v-col cols="12" lg="8" md="8" sm="12" xs="12">
                                    <div class="open-account">Open an account with us today!</div>
                                 </v-col>
                                 <v-col cols="12" lg="4" md="4" sm="12" xs="12">
                                      <div class="create-account">
                                            <v-btn x-large depressed :color="`${zamuPurple}`"  dark href="https://dashboard.zamupay.com/authentication/account/selfsignup" >
                                            Create an account
                                            </v-btn>
                                        </div>
                                 </v-col>

                            </v-row>
                           
                        </v-card-text>
                    </v-card>
            </v-flex>
        </v-layout>
   </section>
</template>

<script>
import colorMixin from '@/mixins/colorMixin';

export default {
    name: 'Banner',
    mixins: [colorMixin],


}
</script>

<style>
.banner-holder {
    background: #d29f2a;
}
.banner {
    background: #d29f2a;
}
.banner .v-card__text {
    display: flex;
}
.open-account {
    font-weight: normal !important;
    font-size: 28px !important;
    line-height: 40px !important;
    text-transform: uppercase;
}
</style>